





































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { Collection, CollectionData, Field, FieldType } from '@/models'
import Fields from '@/components/form/Fields.vue'
import gql from 'graphql-tag'
import _cloneDeep from 'lodash/cloneDeep'

@Component({
  components: { Fields },
  apollo: {
    savedItemData: {
      query: gql`
        query itemData($collectionId: ID, $itemId: ID) {
          savedItemData: documentFromCollection(
            collectionId: $collectionId
            itemId: $itemId
          )
        }
      `,
      variables() {
        return {
          collectionId: this.collection._id,
          itemId: this.itemIds?.length === 1 && this.itemIds[0]
        }
      },
      error() {
        this.savedItemData = { data: {} }
      }
    }
  }
})
export default class CollectionEditAddUpdateItem extends Vue {
  @PropSync('open', { type: Boolean, required: true }) isOpen!: boolean
  @Prop({ type: Object, required: true }) collection!: Collection
  @Prop({ type: Array, default: [] }) itemIds!: string[]
  @Prop({ type: String, default: 'create' }) mode!:
    | 'create'
    | 'duplicate'
    | 'edit'
  @Prop({ type: String, required: true }) environmentId!: string

  savedItemData: CollectionData | null = null
  itemData = { data: {} }
  saving = false
  valid = true

  @Watch('savedItemData')
  updateItemData(itemData: CollectionData) {
    if (itemData?.data) {
      this.itemData = _cloneDeep(itemData)
    } else {
      this.itemData = { data: {} }
    }
  }

  reset() {
    this.itemData = { data: {} }
    this.valid = true
    // @ts-ignore
    this.$refs.fields.reset()
  }

  get fields() {
    const fields: Record<string, Field> = {}
    this.collection.fields?.forEach((f) => (fields[f.name] = f))
    return fields
  }

  async save() {
    if (this.saving) return
    this.saving = true
    try {
      if (this.mode === 'create' || this.mode === 'duplicate') {
        const { data } = await this.$apollo.mutate({
          mutation: gql`
            mutation createOrDuplicate($collectionId: ID, $data: JSON) {
              item: addItem(collectionId: $collectionId, data: $data)
            }
          `,
          variables: {
            collectionId: this.collection._id,
            data: this.itemData.data
          }
        })
        await this.$store.dispatch('snackbar/showSnackbar', {
          text: 'Registro creado exitosamente!'
        })
        this.$emit('updated')
        this.$emit('created', data?.item)
        this.reset()
        this.isOpen = false
      }
      if (this.mode === 'edit') {
        const { data } = await this.$apollo.mutate({
          mutation: gql`
            mutation updateItems(
              $collectionId: ID
              $itemIds: [ID]
              $delta: JSON
            ) {
              updateItems(
                collectionId: $collectionId
                itemIds: $itemIds
                delta: $delta
              )
            }
          `,
          variables: {
            collectionId: this.collection._id,
            itemIds: this.itemIds,
            delta: this.itemData.data
          }
        })
        await this.$store.dispatch('snackbar/showSnackbar', {
          text:
            this.itemIds.length === 1
              ? 'Registro actualizado exitosamente!'
              : 'Registros actualizados exitosamente!'
        })
        this.$emit('updated')
        this.reset()
        this.isOpen = false
      }
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
      // TODO: Handle other kinds of errors
      if (e.graphQLErrors) {
        e.graphQLErrors.forEach((err: any) => {
          if (err.error === 'validationError') {
            this.$emit('validationErrors', err.validationErrors)
            this.$set(this, 'validationErrors', err.validationErrors)
          }
        })
      }
    } finally {
      this.saving = false
    }
  }

  close() {
    this.isOpen = false
    this.itemData = { data: {} }
  }
}
