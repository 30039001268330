var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.savedCollection && _vm.$apollo.loading)?_c('Loading'):(_vm.collection)?_c('v-card',{staticStyle:{"height":"100%"},attrs:{"flat":"","color":"rgba(255, 255, 255, 0)"}},[_c('v-card-text',{staticClass:"collection-fields__card-table",staticStyle:{"height":"calc(100% - 88px)","overflow-y":"scroll"}},[_c('div',[_c('v-slide-y-transition',[(
            _vm.savedCollection &&
            _vm.savedCollection.fields &&
            _vm.savedCollection.fields.length > 0 &&
            _vm.dirty
          )?_c('v-alert',{staticClass:"mb-0",attrs:{"type":"warning"}},[_vm._v(" Modificar o eliminar campos existentes puede llevar a inconsistencias de datos. Por favor proceder con precaución. ")]):_vm._e()],1)],1),_c('v-simple-table',{staticClass:"mb-2 px-1",staticStyle:{"background-color":"transparent"},attrs:{"dense":"","id":"collectiom-fields__table"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"48px"}}),_c('th',{staticClass:"text-left"},[_vm._v("Nombre Interno")]),_c('th',{staticClass:"text-left"},[_vm._v("Nombre para mostrar")]),_c('th',{staticClass:"text-left"},[_vm._v("Tipo de campo")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"48px"}},[_vm._v("Opcional")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"48px"}},[_vm._v("Parámetros")]),_c('th',{staticStyle:{"width":"48px"}})])]),_c('draggable',{attrs:{"tag":"tbody","handle":".field-handle","animation":200,"scroll-sensitivity":128,"force-fallback":true},model:{value:(_vm.collection.fields),callback:function ($$v) {_vm.$set(_vm.collection, "fields", $$v)},expression:"collection.fields"}},_vm._l((_vm.collection.fields),function(field,index){return _c('tr',{key:field._id},[_c('td',{staticClass:"field-handle",staticStyle:{"width":"48px"}},[_c('v-icon',[_vm._v("drag_handle")])],1),_c('td',{class:{
              red: _vm.fieldErrors[field.name],
              'lighten-3': _vm.fieldErrors[field.name]
            }},[_c('v-text-field',{attrs:{"solo":"","dense":"","flat":"","hide-details":"","background-color":"transparent","error":_vm.fieldErrors[index],"label":"Nombre Interno"},on:{"input":function($event){return _vm.validateField(field, index)}},model:{value:(field.name),callback:function ($$v) {_vm.$set(field, "name", $$v)},expression:"field.name"}})],1),_c('td',[_c('v-text-field',{attrs:{"solo":"","dense":"","flat":"","hide-details":"","label":"Nombre para mostrar","background-color":"transparent"},model:{value:(field.label),callback:function ($$v) {_vm.$set(field, "label", $$v)},expression:"field.label"}})],1),_c('td',[_c('v-select',{attrs:{"solo":"","flat":"","dense":"","label":"Tipo de campo","items":_vm.fieldTypesWithIcons,"item-text":"name","item-value":"_id","hide-details":"","background-color":"transparent"},on:{"input":function($event){return _vm.handleFieldTypeChange(field)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s(item.icon)}}),_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(field.type),callback:function ($$v) {_vm.$set(field, "type", $$v)},expression:"field.type"}})],1),_c('td',[_c('v-checkbox',{staticClass:"my-2 mx-3",attrs:{"hide-details":""},model:{value:(field.optional),callback:function ($$v) {_vm.$set(field, "optional", $$v)},expression:"field.optional"}})],1),_c('td',{staticClass:"text-center"},[_c('div',[_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":!_vm.getTypeForField(field) ||
                  !_vm.getTypeForField(field).optionsParams},on:{"click":function($event){return _vm.openOptionsForField(field)}}},[_c('v-icon',[_vm._v("settings")])],1)],1)]),_c('td',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeField(field)}}},[_c('v-icon',[_vm._v("delete")])],1)],1),(
              _vm.getTypeForField(field) && _vm.getTypeForField(field).optionsParams
            )?_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.optionsSheetOpen[field.name]),callback:function ($$v) {_vm.$set(_vm.optionsSheetOpen, field.name, $$v)},expression:"optionsSheetOpen[field.name]"}},[_c('v-sheet',[_c('v-container',[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v("Parámetros adicionales de campo")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.optionsSheetOpen[field.name] =
                        !_vm.optionsSheetOpen[field.name]}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',{staticStyle:{"overflow-y":"auto","max-height":"calc(100vh - 256px)"}},[_c('Fields',{key:field.name,attrs:{"schema":_vm.getTypeForField(field).optionsParams,"environment-variables":{ environmentId: _vm.environmentId, collectionId: _vm.collectionId }},model:{value:(field.options),callback:function ($$v) {_vm.$set(field, "options", $$v)},expression:"field.options"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.optionsSheetOpen[field.name] =
                        !_vm.optionsSheetOpen[field.name]}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("check")]),_vm._v(" Aceptar ")],1)],1)],1)],1)],1):_vm._e()],1)}),0)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-btn',{staticClass:"mx-0",attrs:{"block":"","dark":"","color":"#59a4f4"},on:{"click":_vm.addField}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" Añadir Campo ")],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","icon":"","title":"Revertir Cambios","disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.updateCollection}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"#59a4f4","disabled":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.dismiss}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"#59a4f4","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty || !_vm.valid,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }